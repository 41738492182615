import React, { useEffect, useRef } from 'react';

import { AiOutlineHome, AiFillGithub, AiOutlineTwitter, AiFillYoutube } from 'react-icons/ai';
import { BsInstagram } from 'react-icons/bs';
import {GiHamburgerMenu } from 'react-icons/gi';
import { SiPolywork } from "react-icons/si";
import { IoMdArrowDropdown } from 'react-icons/io';
import { CgMail } from "react-icons/cg";

const Header = () => {

    useEffect(() => {

      const header = document.getElementById('on_no_scroll');
      const header_normal = document.getElementById('header_normal');

        const handleScroll = () => {
          console.log("SCROLLED")
            const currentScrollY = window.scrollY;
      
            console.log(currentScrollY)
            if (currentScrollY > 0) {
              console.log("SCROLLED2")
              header.classList.add('-bottom-24');
              header.classList.remove('bottom-10');
              header_normal.classList.remove('-top-24');
              header_normal.classList.add('top-0');
            } else {
              header.classList.remove('-bottom-24');
              header.classList.add('bottom-10');
              header_normal.classList.add('-top-24');
              header_normal.classList.remove('top-0');
            }
          };
          window.addEventListener('scroll', handleScroll);

          header.classList.remove('-bottom-24');
          header.classList.add('bottom-10');
          header_normal.classList.add('-top-24');
          header_normal.classList.remove('top-0');
          return () => {
              window.removeEventListener('scroll', handleScroll);
            }
          }, []);
  

        return(
        <>
            <div className="fixed -top-24 left-0 w-full h-20 z-50 hidden sm:block ease-in-out duration-200" id="header_normal">
                <div className="w-full bg-black/20 backdrop-blur-3xl p-5 py-3 font-smalltext m-auto text-white">
                <div id="menu" class="h-fit sm:z-0 z-10">
                        <div class="flex gap-3 justify-end w-3/4 m-auto">
                            <section class="sm:inline sm:float-right sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="/"><AiOutlineHome class="float-left w-5 h-6 mr-1 hidden sm:block" /> Home</a></section>
                            <section class="sm:inline sm:float-right  sm:mt-0 hover:text-gray-500 duration-200 ease-in-out hidden"><a href="https://x.com/bendix_en"><AiOutlineTwitter  class="sm:float-left w-7 h-6 mr-1" /></a></section>
                            <section class="sm:inline sm:float-right  sm:mt-0 hover:text-gray-500 duration-200 ease-in-out hidden"><a href="https://www.instagram.com/bendix_en/"><BsInstagram class="sm:float-left w-5 h-6 mr-1" /></a></section>
                            <section class="sm:inline sm:float-right  hover:text-gray-500 duration-200 ease-in-out"><a href="https://github.com/mrbrickstar"><AiFillGithub class="float-left w-5 h-6 mr-1" /></a></section>
                            <section class="sm:inline sm:float-right  sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="https://www.polywork.com/bendix"><SiPolywork class="float-left w-5 h-6 mr-1 hidden sm:block"/></a></section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed left-0 w-full h-10 z-50 ease-in-out duration-200" id="on_no_scroll">
                <div className="w-max bg-black rounded-full p-5 py-3 font-smalltext m-auto text-white">
                <div id="menu" class="h-fit bg-transparent sm:z-0 z-10">
                        <div class="flex gap-3">
                            <section class="sm:inline sm:float-right sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="/"><AiOutlineHome class="float-left w-5 h-6 mr-1 block" /> Home</a></section>
                            <section class="sm:inline sm:float-right  sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="https://x.com/bendix_en"><AiOutlineTwitter  class="sm:float-left w-7 h-6" /></a></section>
                            <section class="sm:inline sm:float-right  sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="https://www.instagram.com/bendix_en/"><BsInstagram class="sm:float-left w-5 h-6" /></a></section>
                            <section class="sm:inline sm:float-right  sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="https://github.com/mrbrickstar"><AiFillGithub class="float-left w-5 h-6" /></a></section>
                            <section class="sm:inline sm:float-right  sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="https://www.polywork.com/bendix"><SiPolywork class="float-left w-5 h-6"/></a></section>
                        </div>
                    </div>
                    </div>  
            </div>
        </>
        )
}

export default Header;

import React from "react";

import { DiReact } from 'react-icons/di';
import { SiTailwindcss } from 'react-icons/si';

class footer extends React.Component{
    render(){
        return(
            <>
                <footer class="h-max w-full text-white mt-auto float-left sm:pl-20 sm:pr-20 pr-5 pl-5 pt-10 pb-10 bg-black">
                    <div className="sm:flex justify-between gap-5 ml-2 sm:w-1/2 sm:min-w-[500px] sm:m-auto font-smalltext">
                    <div class="text-left">
                        <div class="text-gray-700 font-bold text-xl font-header">Imprint</div>
                        Bendix Siegert<br />
                        Bergstraße 40<br />
                        51503 Rösrath<br />
                        Germany<br />
                        <a href="mailto:mail@mrbrickstar.de">mail@mrbrickstar.de</a>
                    </div>
                    <div class="sm:text-right mt-5 sm:mt-0">
                        <div class="text-gray-700 font-bold text-xl font-header">Other stuff</div>
                        <ul>
                            <li class=" hover:text-gray-500 ease-in-out duration-700"><a href="https://www.instagram.com/bendix_en/">Instagram</a></li>
                            <li class=" hover:text-gray-500 ease-in-out duration-700"><a href="https://x.com/bendix_en">Twitter</a></li>
                            <li class=" hover:text-gray-500 ease-in-out duration-700"><a href="https://www.polywork.com/bendix">Polywork</a></li>
                        </ul>
                    </div>
                    </div>
                    <center>
                        <div class="font-handwriting float-left w-full inline-block align-middle mt-20">Made by <a href="https://polywork.com/bendix" class="text-gray-500 hover:text-white ease-in-out font-header duration-700">Bendix Siegert</a> with <DiReact class="w-6 h-6 inline-block align-middle"/> and <SiTailwindcss class="w-6 h-6 inline-block align-middle"/></div>
                    </center>
                </footer>
            </>
        )
    }
}

export default footer;